/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import React from 'react'
// const StateWrapper = require('./src/state/StateWrapper').default
// exports.wrapRootElement = StateWrapper

require('./src/styles/type.less')
require('./src/styles/index.less')

exports.onClientEntry = () => {}
